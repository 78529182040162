$colors: (
  'blue': #334455,
  'black': #000000,
  'ligth-blue': #0222be,
  'lighten-blue': #71788a,
  'azure': #047bf8,
  'zorro-azure': #1890ff,
  'ligth-cyan': #d7ecf9,
  'red': #f94747,
  'red-medium': #dc3e35,
  'red-required': #ff4d4f,
  'red-dark': #bf3a29,
  'red-light': #f8e5e5,
  'ref-row-bg': #f8f9fb,
  'green': #58b968,
  'green-light': #1cf91c,
  'green-log': #b3fcb3,
  'green-checkin': #71db83,
  'green-status': #33de40,
  'orange': #e67e22,
  'orange-light': #ffa234,
  'white': #fff,
  'gold': #a16e00,
  'grey': #f1f4f9,
  'grey-light': #c0c0c0,
  'grey-dark': #858f99,
  'grey-extra-dark': #58606b,
  'grey-checkout': #aaaaaa,
  'inherit': inherit,
  'green-dark': #54a072,
  'grey-border': #e1e1e1,
  'info': #f2d15b,
  'gold-stats-message': #71671b,
  'yellow-stats-message-border': #f1e8a3,
  'yellow-light-stats-message-background': #fcf9e2,
  'blue-total-row': #d3e0f0,
  'table-row-border': #c0c8d5,
  'transparent': transparent,
  'row-hover': #fafafa,
  'label-gray': #a6adb3,
  'booking-table-row-border': #e9eaee,
  'tabset-border': #e8e8e8,
  'tabset-active': #2a3144,
  'tabset-inactive': #9c9c9c,
  'tabset-counter-border': #e0e3e8,
  'filter-reset-button': #a2a2a2,
  'note-icon': #a5aeb5,
  'stats-tabset-background': #32415b,
  'stats-header-gray': #9fa7bc,
  'message-logo-bg': #364353,
  'light-blue-row': #e6f7ff,
  'border-color-base': #c9c9d2,
);

@each $colorName, $colorValue in $colors {
  .color--#{$colorName} {
    color: $colorValue !important;

    &.by-icon {
      background-color: $colorValue;
    }
  }
}

@each $colorName, $colorValue in $colors {
  .by-card-head-color--#{$colorName} {
    & > .ant-card-head {
      background-color: $colorValue !important;
    }
  }
}

@each $colorName, $colorValue in $colors {
  .bg--#{$colorName} {
    background-color: $colorValue !important;
  }
}
