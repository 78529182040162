/* You can add global styles to this file, and also import other style files */
@import './../node_modules/quill/dist/quill.core.css';
@import './../node_modules/quill/dist/quill.bubble.css';
@import './../node_modules/quill/dist/quill.snow.css';
@import './../node_modules/quill-mention/dist/quill.mention.min.css';

@import './variables';

@import './scss/helper.scss';

// INTER-COMPONENT STYLES IMPORTS
@import './scss/tableau/tableau-modal';
@import './scss/reservation-tag-selector';
@import './scss/bottom-title-breadcrumb';
@import './scss/crm/crm-search-results-table';
@import './scss/crm/crm-price-quotation-cart-edit';
@import './scss/crm/crm-new-reservation-cart-edit';
@import './scss/crm/crm-fixed-bar-styles';
@import './scss/crm/crm-summary';
@import './scss/main-navigation';
@import './scss/price-details-popover';
@import './scss/reservation-details/add-room-modal';
@import './scss/reservation-details/estimate-modal';
@import './scss/reservation-details/accommodations-tabset';
@import './scss/reservation-details/guest-tab-date-picker';
@import './scss//reservation-details/reservation-details-cash-desk-total';
@import './scss//reservation-details/guests-tree';
@import './scss/daily-overview/daily-overview-modal';
@import './scss/daily-overview-treatment/daily-overview-treatment-modal';
@import './scss/label-as-checkbox';
@import './scss/global-search-select';
@import './scss/date-range/';
@import './scss/bills/bills-card';
@import './scss/booking-promotions/';
@import './scss/page-header-responsive';
//@import './scss/pass-charges';
@import './scss/widget';
@import './scss/housekeeper/housekeeper';
@import './scss/customizable-table';
@import './scss/rateplans.scss';
@import './scss/beddy-custom-tabset';
@import './scss/colors';
@import './scss/steps';
@import './scss/tabset';
@import './scss/cards';
@import './scss/beddy-icons';
@import './scss/media-query';
@import './scss/table/';
@import './scss/loading-modal-template';
@import './scss/invoice/invoice-tabset';
@import './scss/chart-legend';
@import './scss/bills/moved-row';
@import './scss/add-bill-modal';
@import './scss/add-bill-modal-accommodations-select-dropdown';
@import './scss/suspended-page';
@import './scss/more-properties';
@import './scss/balance-summary-table/';
@import './scss/document-list-responsive/';
@import './scss/accommodations-select/';
@import './scss/price-list/';
@import './scss/reservation-calendar/';
@import './scss/note-collapse';
@import './scss/bill-modal';
@import './scss//user-sessions/';
@import './scss/stats-range-picker/';
@import './scss/reservation-message/';
@import './scss/apply-custom-scrollbar';
@import './scss/messages-hub.scss';
@import './scss/select/round.scss';

@import './scss/changelog-section-collapse/changelog-section-collapse.global';

@import './scss/by-width';

// Globals styles
@import './scss/globals';

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Avenir Next LT Pro Regular'),
    url('../src/assets/avenir-next-lt-pro-cufonfonts-webfont/AvenirNextLTPro-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: bold;
  src:
    local('Avenir Next LT Pro Demi'),
    url('../src/assets/avenir-next-lt-pro-cufonfonts-webfont/AvenirNextLTPro-Demi.woff')
      format('woff');
}

@font-face {
  font-family: 'Avenir Next LT Pro Bold';
  font-style: normal;
  font-weight: bold;
  src:
    local('Avenir Next LT Pro Bold'),
    url('../src/assets/avenir-next-lt-pro-cufonfonts-webfont/AvenirNextLTPro-Bold.woff')
      format('woff');
}

* {
  font-family: 'Avenir Next LT Pro Regular';
  text-shadow: none;
}

@mixin background-lighten-color-10($color) {
  background: lighten($color, 10%) !important;
}

body {
  background: $body--background_color;
  font-size: 13px;
  color: #334455;
  cursor: default;
  overflow-anchor: none;
}

ul {
  margin: 0;
  padding: 0;
  padding-left: 15px;
}

#reservation-list-table-overflow {
  .ant-table {
    padding-top: 7px;
  }
}

#guests-presence-table {
  .ant-table table {
    border-collapse: collapse !important;
    border-spacing: 0;
  }
}

.extrabold {
  font-family: 'Avenir Next LT Pro Bold';
}

.email-template-modal {
  min-width: 1024px !important;
  min-height: 768px !important;

  & .ant-modal-body > div {
    pointer-events: none;
  }
}

.email-templates-container {
  .card-with-shadow {
    box-shadow: none !important;
    .ant-card-head {
      border-bottom: 0 !important;
    }
  }
}
.ant-layout {
  background: none;
}

.ant-popover-title {
  border-radius: 4px 4px 0 0;
  min-width: 140px !important;
}

.overflow-auto {
  overflow: auto !important;
}

.h100-vh {
  height: 100vh;
}

.w-100-vw {
  width: 100vw !important;
}

.no-underline {
  text-decoration: none !important;
}

.barred {
  text-decoration: line-through;
}

.bg-light-grey {
  background: $light-grey;
}

.form-check-input {
  margin-top: 0px;
}

.mt-8 {
  margin-top: 4rem !important;
}

.table-responsive {
  overflow-y: visible !important;
}

.dropdown-item {
  cursor: pointer !important;
}

.col-fixed-170 {
  width: 170px;
}

.table-responsive tr:nth-child(1) .dropdown-menu.show {
  top: 25px !important;
  bottom: auto !important;
}

.dropdown-menu.show {
  left: auto !important;
  right: 10px !important;
  transform: none !important;
}

.step-next-opacity {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.ant-table {
  overflow-x: auto;
  overflow-y: hidden;
}

.by-has-error {
  border-color: #f5222d !important;
  color: #f5222d !important;
}

.by-bg-light-grey {
  background: #f7f9fa;
}

.rounded {
  border-radius: 50%;
}

@for $i from 1 through 40 {
  .by-border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}

.by-border-top {
  border-top: 1px solid #dee2e6 !important;
}

.by-border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.by-border-gray {
  border: 1px solid #dee2e6 !important;
}

.cutted-text {
  text-decoration: line-through;
}

.full-page-feature {
  margin: 0 -25px;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  word-break: initial !important;
}

.disabled-card {
  opacity: 0.5;
  pointer-events: none;
}

.by-th-background {
  background-color: #293144 !important;
  padding-top: 7px !important;
  line-height: 2 !important;
}

.by-custom-select {
  width: 100%;
  color: #5f5f5f;
  border-radius: 4px;
  border: 2px solid #dee2ed;
  background: #fff;
  height: 34px;
}

.base-shadow {
  box-shadow: 2px 4px 30px -5px rgba(0, 0, 0, 0.41);
}

.by-link {
  font-size: 12px;
  color: #3498db;
}

.by-link:hover {
  @include background-lighten-color-10(#3498db);
  text-decoration: none !important;
}

.square {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: #000000;
  font-size: 9px;
  line-height: 14px !important;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}

.page-header .ant-btn-primary {
  background-color: #057bf7;
  border: none !important;
}

.page-header .ant-btn-primary:hover {
  @include background-lighten-color-10(#057bf7);
}

.ant-btn-danger {
  background-color: #e7503b !important;
  border: none !important;
  color: #ffffff !important;
}

.danger-link {
  color: #e7503b !important;
}

.ant-btn-danger:hover {
  @include background-lighten-color-10(#e7503b);
}

.ant-btn-grey {
  background-color: #8f9396 !important;
  border-color: #8f9396 !important;
  color: #fff !important;
}

.ant-btn-grey:hover {
  @include background-lighten-color-10(#8f9396);
}

.ant-table-content tr:nth-child(even) {
  background-color: #fff;
}
.ant-table-content tr:nth-child(odd) {
  background-color: #fdfdfe;
  &.ant-table-placeholder {
    background: #fff;
  }
}

.ant-table-thead > tr:not(:last-child) > th[colspan],
.ant-table-thead > tr > th {
  background-color: #fff;
  border-left: 1px solid #e0e3ea;
  border-bottom: 1px solid #e0e3ea;
  height: 25px;
}

.ant-table-content tr {
  border-bottom: unset !important;
}

.ant-table-thead > tr:first-child > th:first-child {
  border-left: none;
}

.ant-table-tbody > tr > td:first-child {
  border-left: none;
}

#bulk-update-calendar .ant-table-tbody > tr > td {
  font-size: 9px;
}

#bulk-update-calendar .ant-table-thead > tr > th {
  text-align: center;
  padding: 0px;
  min-width: 25px;
  vertical-align: middle;
}

.name-accommodations-span {
  color: #bfbfbf !important;
  font-size: 9px;
  display: block;
}

.ant-table-tbody > tr > td {
  padding: 11px;
  border-left: 1px solid #e0e3ea;
  border-bottom: 1px solid #c0c8d5;
}

.cellDayWeek {
  background-color: #fdecf6 !important;
}

.cell-month {
  text-transform: uppercase;
}

.ant-table-thead > tr:not(.by-remove-override) > th {
  padding: 0px 11px;
  vertical-align: middle;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 11px;

  & * {
    font-size: 11px;
  }

  &.no-uppercase {
    text-transform: none;
  }
}

.ant-table-thead .ant-table-column-sorter {
  height: 10px;
}

.ant-table-content > table {
  padding-top: 20px;
  border-top: 0px solid #e0e3ea;
  border-radius: unset;
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #ffffff !important;
}

.ant-table-column-sorter .anticon-caret-up,
.ant-table-column-sorter .anticon-caret-down {
  font-size: 11px !important;
  margin-top: 1px !important;
}

.ant-table-column-sorters::before {
  display: none;
}

.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
  .ant-table-filter-icon,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
  .anticon-filter {
  background: #fff !important;
}

.ant-pagination-item {
  border-radius: unset;
  color: #047bf8;
  margin: 0px;
  border: 1px solid #dee1e6;
}

.ant-pagination-item-active a {
  color: #fff;
}

.footerModal > hr {
  border: #fff;
}

.ant-pagination-item-link {
  border-radius: 4px 0px 0px 4px !important;
  margin: 0px !important;
  color: #047bf8 !important;
}

.ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px 4px 4px 0px !important;
}

.ant-pagination-prev {
  margin-right: 0px;
}

.ant-pagination-disabled {
  display: none !important;
}

.ant-pagination-item-active {
  border-radius: unset;
  color: #ffffff;
  background-color: #047bf8;
  border: none;
}

.ant-divider {
  color: #f2f2f2;
}

.icon-link-rule {
  font-weight: bold;
  color: #e7503b;
  padding-top: 3px;
}

#accommodationsTable table {
  border: none !important;
}

#noPaddingTable table {
  padding: 0px !important;
}

#weekdayButton .ant-btn-group .ant-btn {
  border-radius: unset !important;
  border: 1px solid #dee1e6;
  text-transform: uppercase;
  font-size: 9px;
}

#weekdayButton .ant-btn-group .ant-btn-danger {
  font-size: 12px;
  background-color: transparent !important;
  color: #e7503b !important;
}

#weekdayButton .ant-btn-group .ant-btn-danger:hover {
  color: #ff7980 !important;
}

#weekdayButton .ant-btn.active,
#weekdayButton .ant-btn:active {
  border-radius: unset;
  color: #ffffff !important;
  background-color: #34495e !important;
  font-size: 9px;
}

#weekdayButton .ant-btn-group {
  margin-top: -4px;
}

.default {
  cursor: default !important;
}

.pointer {
  cursor: pointer !important;
}

.by-mover {
  cursor: move !important;
}

.italic {
  font-style: italic;
}

.unselectable {
  user-select: none;
}

.pointer[by-disabled='false'] {
  cursor: initial;
}

.by-lock-unlock {
  cursor: pointer;
  color: #4c84ff;
  font-weight: bold;
  font-size: 13px;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background-color: #f5f5f5 !important;
}

.cell-room .ant-input-number:hover .ant-input-number-handler-wrap {
  display: none;
}

.cell-room .ant-input-number-input {
  height: 24px;
  padding: 0px;
  text-align: center;
}

.cell-form .ant-input-number-input {
  height: 22px !important;
  padding: 0px;
  text-align: center;
  background: #f3f3f3;
}

//risolto problema per chromo dropdown
// .cdk-overlay-connected-position-bounding-box {
//   flex-direction: unset !important;
// }

.cdk-overlay-container {
  z-index: 1000;
}

.by-ml-18 {
  margin-left: 18px;
}

.by-ml-36 {
  margin-left: 36px;
}

.by-mr-36 {
  margin-right: 36px;
}

.by-mr-18 {
  margin-right: 18px;
  color: #646466 !important;
  font-size: 12px !important;
}

.nzSelectAllSpan {
  text-transform: capitalize !important;
  color: #2f9bfe !important;
}

.noselectText {
  user-select: none;
}

.card-bounding {
  width: 90%;
  max-width: 500px;
  position: relative;
  margin: 0 auto;
  padding: 26px;
  border: 1px solid #3e73ea;
  border-radius: 6px;
  background: #ffffff;
  font-size: 12px !important;
  margin-bottom: 11px;
}

.card-select-type {
  min-width: 48px;
  width: 138px !important;
  right: 26px;
  top: 8px;
  position: absolute;
}

.card-container {
  width: 100%;
  padding-right: 40px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 0 auto 16px auto;
}

.card-container {
  input {
    width: 100%;
    letter-spacing: 1px;
    padding: 15px 15px 15px 25px;
    border: 0;
    outline: none;
    box-sizing: border-box;
  }
}

.card-type {
  width: 80px;
  height: 56px;
  background-position: 0 -291px;
  background-repeat: no-repeat;
  top: 3px;
  left: 4px;
}

.card-details {
  width: 100%;
  text-align: left;
  transition: 300ms ease;
}

.card-details input {
  box-sizing: border-box;
  width: 100%;
}

.card-details .expiration {
  width: 50%;
  float: left;
  padding-right: 5%;
}

.card-details .cvv {
  width: 45%;
  float: left;
}

.text-align {
  &--start {
    text-align: left !important;
  }

  &--end {
    text-align: right !important;
  }

  &--center {
    text-align: center !important;
  }

  &--justify {
    text-align: justify !important;
  }
}
.border-none {
  &--left {
    border-left: none !important;
  }

  &--right {
    border-right: none !important;
  }

  &--top {
    border-top: none !important;
  }

  &--bottom {
    border-bottom: none !important;
  }
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item {
  font-size: 14px !important;
  color: #334455;
}

.filters__select-options {
  .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #ffffff !important;
    border: 0 none !important;
    line-height: 24px !important;
  }
}

.ant-modal-header {
  cursor: move;
}

.border-divider {
  border: 1px solid #dee1e6;
}

.floating-horizontal-scroll {
  position: fixed;
  bottom: 1px;
  height: 30px;
  overflow: auto hidden;
  display: block;
  z-index: 10;
}

#stickyTable .ant-table-thead > tr > th {
  padding-top: 5px;
  position: relative;
}

#sticky {
  & .ant-table-header {
    will-change: transform;
  }

  & .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container,
  & .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
    position: unset;
  }
}

#stickyTable .ant-table-content > table {
  padding-top: 0;
}

.tr-first {
  text-align: right !important;
}

.tr-info:nth-child(even) {
  & td {
    background: #f7fcff !important;
  }
}
.tr-info:nth-child(odd) {
  & td {
    background: #ecf8fe !important;
  }
}

#tabs-page-c {
  & > .ant-tabs-nav {
    background: #33415b;
    padding-left: 50px;
    padding-bottom: 3px;

    font-size: 17px !important;
    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      .ant-tabs-tab-btn {
        color: #ffffff !important;
      }
    }
  }
}

#card-style-custom {
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 30px 1px rgba(221, 226, 236, 1);
}

.ant-card-body {
  padding: 15px 20px !important;
}

.card-without-padding .ant-card-body {
  padding: 0px !important;
}

.card-without-top-padding .ant-card-body {
  padding-top: 0px !important;
}

.card-without-bottom-padding .ant-card-body {
  padding-bottom: 0px !important;
}

.card-without-side-padding .ant-card-body {
  padding: 15px 0px !important;
}

.card-crm:not(.card-crm-summary) .ant-card-body {
  padding: 15px !important;
  background: #f7f8fc;
}
.card-crm-show:not(.card-crm-summary) .ant-card-body {
  padding: 15px !important;
  background: #ffffff;
}

.card-crm.card-crm-summary .ant-card-body {
  padding: 15px 15px 20px 15px !important;
  background: #f7f8fc;
}
.card-crm-show.card-crm-summary .ant-card-body {
  padding: 15px 15px 20px 15px !important;
  background: #ffffff;
}

.spin-without-margin nz-spin {
  margin: 0 -25px;

  nz-card {
    background: #f1f4f9;

    .ant-card-body .by-mt-2 {
      margin-top: 0px !important;
    }
  }

  .card-cash-desk {
    nz-card {
      background: #ffffff;
    }
  }

  .card-daily-meals {
    nz-card {
      background: #ffffff;
    }
  }
}

.badge {
  &--small {
    & nz-badge-sup {
      min-width: 15px;
      height: 15px;
      padding: 0 3px;
      font-size: 11px;
      line-height: 15px;
    }
  }
}

.animation-card-res {
  animation-duration: 2s;
  &:hover {
    color: #047bf8;
    animation-duration: 1s;
    transform: translateY(-2px) scale(1.01);
    box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  }
}

.reservation-log-container {
  mark {
    background-color: yellow;
  }
}
.by-fl {
  float: left;
}
.by-fr {
  float: right;
}
// serve per firefox che nel th toglie il border
.ant-table-thead > tr > th.ant-table-column-has-actions,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  -webkit-background-clip: padding-box !important;
  background-clip: padding-box !important;
}

.ant-modal-wrap.whatsapp-modal-wrap {
  top: -90px;
}

// .ant-modal-wrap.send-email-modal-wrap {
//   top: -90px;
// }

.ant-modal.send-email-modal-wrap {
  top: 20px;
  width: 1024px !important;
  min-height: 768px !important;
}

.send-email-modal-wrap {
  top: 20px;
  min-width: 1024px !important;
  min-height: 768px !important;
}

#selectAllNoValue {
  ul {
    .ant-select-selection__choice {
      display: none !important;
    }
  }
  .ant-select-selection-item {
    background: transparent;
    border: none;
  }
}

.filterSelectAllSpan > div > ul > li:first-child {
  text-transform: capitalize !important;
  color: #2f9bfe !important;
}

#selectAllNoValue:not(.ant-select-open) .ant-select-selection__placeholder {
  display: block !important;
  text-transform: capitalize !important;
}

#selectAllNoValue.ant-select-open .ant-select-selection__placeholder {
  display: none !important;
}

.filterSelectAllSpan {
  text-transform: capitalize !important;
  color: #2f9bfe !important;
}

.no-print {
  @media print {
    display: none !important;
  }
}

@media print {
  body {
    background: #fff;
  }

  .ant-checkbox-input,
  .ant-checkbox-inner,
  .ant-notification {
    display: none !important;
  }

  * {
    color: black !important;
  }

  // .ant-table-row-expand-icon {
  //   display: none;
  // }

  nz-card {
    box-shadow: none !important;
    border: 1px solid black;
  }

  #reservation-payments .ant-tabs-content {
    border: none !important;
  }

  #reservation-details-payments .ant-tabs-content {
    border: none !important;
  }

  #reservation-payments .ant-table {
    border-top: 1px solid black !important;
  }

  #reservation-details-payments .ant-table {
    border-top: 1px solid black !important;
  }

  .reservation-detail-page {
    .page-padding,
    .pane {
      padding: 0 !important;
      background: white !important;
    }
    .pane {
      margin-top: -45px;
    }
  }

  .div-total {
    color: black !important;
    background: transparent !important;
  }

  svg,
  button,
  .anticon,
  .ant-tabs-bar,
  .ant-table-row-expand-icon-cell {
    display: none !important;
  }
}

// @media print {
//   nz-footer,
//   nz-sider {
//     display: none !important;
//   }

//   #printable {
//     background-color: white;
//     position: fixed;
//     float: left;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     margin: 0;
//     padding: 0;
//     font-size: 14px;
//     line-height: 18px;
//     page-break-before: always;
//     color: black !important;
//     z-index: 1000000;
//     * {
//       color: black !important;
//     }

//     .card-shadow {
//       box-shadow: none !important;
//     }

//     .ant-card-body {
//       border: 1px solid black !important;
//     }

//     th {
//       text-align: left !important;
//     }

//     .anticon,
//     i.far,
//     td > svg,
//     button {
//       display: none !important;
//     }
//   }
// }

// Removes : after any label
.ant-form-item-label label::after {
  content: ' ';
  margin: 0;
}
// fix per firefox Fabrizio
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  padding-bottom: 0px !important;
  background: #ffffff;
}

.add-bill-modal-small {
  width: 700px !important;
  .ant-card-body {
    padding: 0 10px !important;
  }
}

.add-bill-modal-large {
  width: 98% !important;
  .ant-card-body {
    padding: 0 10px !important;
  }
}

.add-bill-modal-wrap {
  top: -80px;
}
.toolbar-container {
  .ant-select-selection {
    border-color: #d9d9d9 !important;
  }
  .ant-select-arrow {
    color: #d9d9d9 !important;
  }
}

.by-cdk-overlay-drag {
  position: relative !important;
}

.ant-calendar-month-panel-header {
  display: none !important;
}

.add-bill-transfer-container {
  .ant-table-tbody > tr > td.row-validation {
    border: 1px solid red;
  }
}

.container-button-multiselect {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4px;
  padding-right: 6px;
}

.cdk-global-overlay-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.radio_group_move_reservation_modal .ant-radio-wrapper {
  & > :not(.ant-radio) {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-around;
  }
}

// /* Animate items as they're being sorted. */
// .cdk-drop-list-dragging .cdk-drag {
//   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

// /* Animate an item that has been dropped. */
// .cdk-drag-animating {
//   transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
// }

#dragGalleryCreate {
  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
  }
}

.required-input-red {
  align-items: center;
  display: inline;

  & .ant-form-item-label {
    width: unset;
  }

  &::before {
    content: none;
    margin-right: 0px;
  }

  &::after {
    margin-left: 1px !important;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.label-truncate {
  white-space: initial;
  line-height: 1.5;
}

.input {
  &--number {
    width: map-get($input-group-width, 'number');
  }

  &--currency {
    width: map-get($input-group-width, 'currency');
  }

  &--time {
    width: map-get($input-group-width, 'time');
  }

  &--select-medium {
    max-width: map-get($input-group-width, 'select-medium__max');
    min-width: map-get($input-group-width, 'select__min');
  }

  &--select-small {
    max-width: map-get($input-group-width, 'select-small__max');
    min-width: map-get($input-group-width, 'select__min');
  }
}

.no-margin-page {
  margin: 0 -16px;
}

.relative {
  position: relative;
}

.by-delete-color {
  color: #f5222d;
}

.ellipsis_50pr-25px {
  max-width: calc(50% - 25px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ellipsis_50pr-50px {
  max-width: calc(50% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.by-text-overflows--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.nowrap-text {
  white-space: nowrap;
  overflow: hidden;
}

.translateXeffect,
.translateXeffect i {
  transition: all 0.3s;

  &:hover {
    transform: translateX(5px);

    & i {
      color: #fff !important;
    }
  }

  &--submenu {
    & .ant-menu-submenu-title {
      & div,
      & div i {
        transition: all 0.3s;
      }

      &:hover > div {
        transform: translateX(5px);

        & i {
          color: #fff !important;
        }
      }
    }
  }
}

.ant-layout-sider {
  z-index: 1000;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: inline-block;
}

.btn-no-style {
  background: unset !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.light-grey-vertical-divisor {
  background-color: #dbdbe4;
  width: 1px;
  height: 45px;
}

.w-60px {
  width: 60px !important;
}
.w-90px {
  width: 90px !important;
}

.card-with-shadow {
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12) !important;
  border-radius: 6px !important;
  overflow: hidden;
}

.full-width-card {
  width: calc(100% + 40px);
  transform: translateX(-20px);
}

by-reservation-list-table .full-width-card {
  width: auto !important;
  transform: none !important;
}

.crm-cart-tabs {
  & .ant-tabs-tab-active {
    background: linear-gradient(to bottom, #fff, #f1f4f9) !important;
    border-bottom: none !important;
  }

  & .ant-tabs-tab {
    padding: 0 10px !important;
  }

  // fix altezza del contenuto delle tabs
  .ant-tabs-content {
    height: 100% !important;
  }
}

.by-block {
  display: block;
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.no-border {
  border: none !important;
  border-radius: 0px !important;
}

by-divider {
  display: block;
  padding: 0;
}

.reservation-list-table th {
  padding: 0 5px !important;
  vertical-align: middle;

  & .ant-table-column-title {
    font-size: 11px;
    white-space: nowrap;
  }
}

.white-text {
  & * {
    color: #fff;
  }

  &.disabledButton {
    background-color: #f5f5f5 !important;
  }
}

.text-wrap {
  white-space: initial !important;
}

.nowrap {
  white-space: nowrap !important;
}

.text-like-link {
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #334455d1;
  }
}

.fa-birthday-cake {
  color: #ab299b;
}

.remove-1px-bottom {
  margin-bottom: -1px;
}

.flexFormWithFullWidth {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.no-pt {
  padding-top: 0 !important;
}

.clickable-icon-blu {
  color: #047bf8;
  transition: all 0.3s;

  &:hover {
    color: #047bf880;
  }
}

.hidden {
  display: none !important;
}

.fontawesome-avatar {
  background: #00000047;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 20px;
}

.bolder {
  font-weight: bolder !important;
}

.bold {
  font-weight: bold;
}

.user-avatar-template {
  background-color: #cacaca;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;

  &.big {
    height: 40px;
    width: 40px;
    font-size: 18px;
  }
}

.fas.fa-ellipsis-v:not(.no-override) {
  font-size: 18px;
  color: #047bf8;
  padding: 0 5px;
  transition: all 0.3s;
  backface-visibility: hidden;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}
.ellipsis-td {
  text-align: center !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// [CASH-DESK] STILE COLONNA "IMPORTO"
.cash-desk-list-payment {
  &-in by-currency-format {
    font-weight: 600;
    color: #58b968;
  }

  &-out by-currency-format {
    font-weight: 600;
    color: #f94747;
  }
}

// nelle nz-table con questa classe i th verranno indentati a sinistra
.left-indentetion-table-header {
  & table > thead > tr > th {
    text-align: left;
  }
}

// flex layout class
.flex-layout {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
}

// color picker button OK style
.color-picker-button-ok {
  background-color: #047bf8;
  color: #fff;
  border: 0;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
}

// color picker remove hex input
.color-picker-no-text {
  & + color-picker {
    & .type-policy,
    & .hsla-text,
    & .rgba-text,
    & .hex-text {
      display: none !important;
    }
  }
}

.opacityClass {
  opacity: 0.5 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.daily-adr-widget {
  .numeric-stat-widget-container_info-month
    .month-widget
    .reservation-date-separator {
    color: #05c9a7;
  }
  .slider-percentage .slider-percentage-def {
    background-color: #05c9a7;
  }
}

.monthly-sold-nights-widget {
  .numeric-stat-widget-container_info-month
    .month-widget
    .reservation-date-separator {
    color: #ffc107;
  }
  .slider-percentage .slider-percentage-def {
    background-color: #ffc107;
  }
}

.monthly-conversion-widget {
  .numeric-stat-widget-container_info-month
    .month-widget
    .reservation-date-separator {
    color: #886cff;
  }
  .slider-percentage .slider-percentage-def {
    background-color: #886cff;
  }
}

.daily-occupancy-widget {
  .numeric-stat-widget-container_info-month
    .month-widget
    .reservation-date-separator {
    color: #3f87f5;
  }
  .slider-percentage .slider-percentage-def {
    background-color: #3f87f5;
  }
}

.ant-form-item-required::before {
  display: none;
}

.ant-form-item-required::after {
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  margin-left: 1px !important;
  line-height: 1;
  content: '*' !important;
}

//ota logo
.ota-logo-channel {
  width: 21px;
  margin: -3px 9px 0px 0px;
}

.select-property-groups-component-popover {
  margin-left: 25px;
  & .ant-popover-arrow {
    border-color: #293144 !important;
  }
  & .ant-popover-inner {
    background: #293144;

    & .ant-popover-inner-content {
      color: #fff;
      font-weight: 700;
    }
  }
}

@for $i from 1 through 36 {
  .by-gap-#{$i} {
    gap: #{$i}px !important;
  }
}

@for $i from 1 through 40 {
  .by-fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

.by-justify-content {
  &--space-between {
    justify-content: space-between;
  }
  &--space-around {
    justify-content: space-around;
  }
  &--flex-end {
    justify-content: flex-end;
  }
  &--center {
    justify-content: center;
  }
}

.by-align-items {
  &--center {
    align-items: center;
  }
  &--baseline {
    align-items: baseline;
  }
  &--flex-end {
    align-items: end;
  }
}

.by-flex-direction {
  &--column {
    flex-direction: column !important;
  }

  &--row {
    flex-direction: row !important;
  }
}

.by-flex-1 {
  flex: 1;
}

.by-flex {
  display: flex !important;

  &-column {
    flex-direction: column;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }

  &-middle {
    align-items: center;
  }

  &-align-flex-start {
    align-items: flex-start;
  }

  &-align-flex-center {
    align-items: center;
  }

  &-align-flex-end {
    align-items: flex-end;
  }

  &-base {
    align-items: baseline;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-nowrap {
    flex-wrap: nowrap;
  }

  &-grow {
    flex-grow: 1;
  }

  &-shrink {
    flex-shrink: 1;
  }
  &-nogrow {
    flex-grow: 0;
  }

  &-1 {
    flex: 1;
  }
}

.sidebar-divider {
  border-bottom: 1px solid #364154;
}

// NG-ZORRO OVERRIDE
@import './scss/ng-zorro-override';

#beddy-bar-container .bb__inner {
  z-index: 999;
}

.font-small {
  font-size: 10px;
  font-style: italic;
}

by-currency-format {
  white-space: nowrap;
}

.filters-container {
  .ant-radio-button-wrapper {
    padding: 0 20px;
  }
}
.question {
  display: flex;
  justify-content: flex-start;

  .label-question {
    margin-right: 39px !important;
    line-height: 30px;
  }

  .table-alternate-color {
    tr:nth-child(odd) {
      background: #f8f9fb;
    }
    tr:nth-child(even) {
      background: #fff;
    }
  }
}

.radio-filter {
  display: flex;
  margin-right: 10px;
}

.font-w-100 {
  font-weight: 100 !important;
}

.by-filters {
  .places-select-container {
    .ant-select-clear {
      top: 39% !important;
      right: 54px !important;
    }

    .ant-btn-sm {
      height: 19px;
    }

    .ant-btn:not(.ant-btn-icon-only) {
      padding: 0 7px;
      display: flex;
      align-items: center;
    }
  }
}

.places-form-select {
  .ant-select-clear {
    top: 39% !important;
    right: 54px !important;
  }

  .ant-btn-sm {
    height: 19px;
  }

  .ant-btn:not(.ant-btn-icon-only) {
    padding: 0 7px;
    display: flex;
    align-items: center;
  }
}

.sort-legend-popover {
  .ant-popover-inner {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .ant-popover-arrow {
    border-right-color: rgba(0, 0, 0, 0.75) !important;
    border-bottom-color: rgba(0, 0, 0, 0.75) !important;
  }
}

.flag-icon {
  background-size: cover;
}

.by-object-cover {
  object-fit: cover;
}

.option-container {
  .option-question {
    height: 35px;
    padding: 2px 0px;
    border-radius: 0px;
    width: 57px;
    text-align: center;

    &.ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
  }

  .option-question-extended {
    height: auto;
    padding: 5px 15px;
    border-radius: 0px;
    text-align: center;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}

.ag-cell .ag-cell-auto-height .editable {
  display: flex;
  align-items: center;
}
.ag-checkbox-input-wrapper {
  margin-left: 45px;
}

//FIX PER TABELLA DETTAGLIO PRENOTAZIONI, LISTA CAMERE

.reservation-details-room-table-grid {
  .ag-selection-checkbox {
    width: 100%;
    margin-right: 0 !important;
  }
  .ag-checkbox-input-wrapper {
    margin-left: 5px;
  }
}

.filter-divider {
  color: #e8e8e8 !important;
  background: transparent;

  .ant-divider-inner-text {
    color: #334455;
  }
}

// Fixa la posizione dellimagine della galleria dopo che è stata visualizzata una notifica
.cdk-overlay-container {
  display: grid;
}

.multi-select {
  /*   .ant-select-selector {
    color: #bfbfbf;
  } */
  .ant-select-selection-item {
    background: transparent;
    border: none;
  }

  &--without-search {
    nz-select-search {
      display: none;
    }
  }
}

.single-box {
  align-items: center;
}

.modalDraggableMove {
  cursor: move;
}

.stats-chart-histogram {
  .ngx-charts .grid-panel.odd rect {
    fill: none !important;
  }
}

.locked {
  pointer-events: none;
}

.button-without-borders {
  border: 0;
}

.big-font-row {
  font-size: 14px !important;
}

.crm-discount-modal {
  .page-header-component {
    padding-top: 0;
  }
}

.by-fs-12 {
  font-size: 12px;
}

.by-lh-30px {
  line-height: 30px;
}

.price-through {
  text-decoration: line-through;
}

.by-px-10 {
  padding: 0 10px !important;
}

@for $i from 1 through 9 {
  .by-opacity-#{$i} {
    opacity: calc(#{$i}/ 10) !important;
  }
}

.example-handle {
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}
